let treeMenuData = [
    {
        "id": 429027966505029,
        "children": [],
        "pid": 0,
        "name": "首页",
        "code": "com_welcome",
        "type": 1,
        "icon": "folder-open",
        "router": "/welcome",
        "component": "system/index/welcome",
        "permission": "",
        "application": "manage",
        "openType": 1,
        "visible": "Y",
        "link": null,
        "redirect": "",
        "weight": 2,
        "sort": 33,
        "remark": null
    },
    {
        "id": 411267457077317,
        "children": [
            {
                "id": 420468073676869,
                "children": [
                    {
                        "id": 420850881904709,
                        "children": [],
                        "pid": 420468073676869,
                        "name": "巡视新增",
                        "code": "btn_projectLedger_add",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "projectLedger:add",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 2,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 411267457077317,
                "name": "项目台账",
                "code": "supervision_projectMag_projectLedger",
                "type": 1,
                "icon": null,
                "router": "/projectMag/projectLedger",
                "component": "supervision/projectMag/projectLedger/projectLedger",
                "permission": "",
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": "",
                "weight": 2,
                "sort": 100,
                "remark": null
            },
            {
                "id": 411268375773253,
                "children": [
                    {
                        "id": 417651811078213,
                        "children": [],
                        "pid": 411268375773253,
                        "name": "项目新增",
                        "code": "project_info_add",
                        "type": 2,
                        "icon": "",
                        "router": "sysOrg:add",
                        "component": "",
                        "permission": "projectInfo:add",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 411267457077317,
                "name": "项目信息",
                "code": "supervision_projectMag_projectInfo",
                "type": 1,
                "icon": null,
                "router": "/projectMag/projectInfo",
                "component": "supervision/projectMag/projectInfo/projectInfo",
                "permission": "",
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": "",
                "weight": 2,
                "sort": 100,
                "remark": null
            }
        ],
        "pid": 0,
        "name": "项目管理",
        "code": "sup_projectMag",
        "type": 0,
        "icon": "radius-upleft",
        "router": "/projectMag",
        "component": "PageView",
        "permission": "",
        "application": "manage",
        "openType": 1,
        "visible": "Y",
        "link": null,
        "redirect": "",
        "weight": 1,
        "sort": 98,
        "remark": null
    },
    {
        "id": 411232431943749,
        "children": [
            {
                "id": 417639603929157,
                "children": [
                    {
                        "id": 417639793692741,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户查询",
                        "code": "sys_user_mgr_page1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:page1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417639957336133,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户编辑",
                        "code": "sys_user_mgr_edit1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:edit1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417640110710853,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户增加",
                        "code": "sys_user_mgr_add1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:add1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417640256938053,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户删除",
                        "code": "sys_user_mgr_delete1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:delete1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417640393457733,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户详情",
                        "code": "sys_user_mgr_detail1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:detail1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417640529092677,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户导出",
                        "code": "sys_user_mgr_export1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:export1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417640675475525,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户选择器",
                        "code": "sys_user_mgr_selector1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:selector1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417640803393605,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户授权角色",
                        "code": "sys_user_mgr_grant_role1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:grantRole1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417640943951941,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户拥有角色",
                        "code": "sys_user_mgr_own_role1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:ownRole1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417641096958021,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户授权数据",
                        "code": "sys_user_mgr_grant_data1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:grantData1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417641227079749,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户拥有数据",
                        "code": "sys_user_mgr_own_data1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:ownData1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417641353084997,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户更新信息",
                        "code": "sys_user_mgr_update_info1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:updateInfo1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417641484509253,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户修改密码",
                        "code": "sys_user_mgr_update_pwd1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:updatePwd1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417641635221573,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户修改状态",
                        "code": "sys_user_mgr_change_status1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:changeStatus1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417641773924421,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户修改头像",
                        "code": "sys_user_mgr_update_avatar1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:updateAvatar1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417641906765893,
                        "children": [],
                        "pid": 417639603929157,
                        "name": "用户重置密码",
                        "code": "sys_user_mgr_reset_pwd1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysUser:resetPwd1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 411232431943749,
                "name": "用户管理",
                "code": "sys_user_mgr1",
                "type": 1,
                "icon": null,
                "router": "/mgr_user",
                "component": "system/user/index",
                "permission": "",
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": "",
                "weight": 1,
                "sort": 100,
                "remark": null
            },
            {
                "id": 417642122018885,
                "children": [
                    {
                        "id": 417642290339909,
                        "children": [],
                        "pid": 417642122018885,
                        "name": "菜单授权树",
                        "code": "sys_menu_mgr_grant_tree1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysMenu:treeForGrant1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417642502541381,
                        "children": [],
                        "pid": 417642122018885,
                        "name": "菜单树",
                        "code": "sys_menu_mgr_tree1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysMenu:tree1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417642634575941,
                        "children": [],
                        "pid": 417642122018885,
                        "name": "角色查询",
                        "code": "sys_role_mgr_page1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysRole:page1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417642767761477,
                        "children": [],
                        "pid": 417642122018885,
                        "name": "角色增加",
                        "code": "sys_role_mgr_add1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysRole:add1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417642902929477,
                        "children": [],
                        "pid": 417642122018885,
                        "name": "角色编辑",
                        "code": "sys_role_mgr_edit1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysRole:edit1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417643030847557,
                        "children": [],
                        "pid": 417642122018885,
                        "name": "角色删除",
                        "code": "sys_role_mgr_delete1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysRole:delete1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417643153317957,
                        "children": [],
                        "pid": 417642122018885,
                        "name": "角色详情",
                        "code": "sys_role_mgr_detail1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysRole:detail1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417643298451525,
                        "children": [],
                        "pid": 417642122018885,
                        "name": "角色下拉",
                        "code": "sys_role_mgr_drop_down1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysRole:dropDown1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417643412770885,
                        "children": [],
                        "pid": 417642122018885,
                        "name": "角色授权菜单",
                        "code": "sys_role_mgr_grant_menu1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysRole:grantMenu1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417643530461253,
                        "children": [],
                        "pid": 417642122018885,
                        "name": "角色拥有菜单",
                        "code": "sys_role_mgr_own_menu1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysRole:ownMenu1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417643664375877,
                        "children": [],
                        "pid": 417642122018885,
                        "name": "角色授权数据",
                        "code": "sys_role_mgr_grant_data1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysRole:grantData1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417643778854981,
                        "children": [],
                        "pid": 417642122018885,
                        "name": "角色拥有数据",
                        "code": "sys_role_mgr_own_data1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysRole:ownData1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 411232431943749,
                "name": "角色管理",
                "code": "sys_role_mgr1",
                "type": 1,
                "icon": null,
                "router": "/role",
                "component": "system/role/index",
                "permission": "",
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": "",
                "weight": 1,
                "sort": 100,
                "remark": null
            },
            {
                "id": 416275052470341,
                "children": [
                    {
                        "id": 416275709554757,
                        "children": [],
                        "pid": 416275052470341,
                        "name": "机构查询",
                        "code": "sys_org_mgr_page1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysOrg:page",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 416275890372677,
                        "children": [],
                        "pid": 416275052470341,
                        "name": "机构列表",
                        "code": "sys_org_mgr_list1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysOrg:list",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 416276136554565,
                        "children": [],
                        "pid": 416275052470341,
                        "name": "机构增加",
                        "code": "sys_org_mgr_add1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysOrg:add",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 416276281966661,
                        "children": [],
                        "pid": 416275052470341,
                        "name": "机构编辑",
                        "code": "sys_org_mgr_edit1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysOrg:edit",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 416276458782789,
                        "children": [],
                        "pid": 416275052470341,
                        "name": "机构删除",
                        "code": "sys_org_mgr_delete1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysOrg:delete",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 416276794884165,
                        "children": [],
                        "pid": 416275052470341,
                        "name": "机构树",
                        "code": "sys_org_mgr_tree1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysOrg:tree",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417587083391045,
                        "children": [],
                        "pid": 416275052470341,
                        "name": "机构详情",
                        "code": "sys_org_mgr_detail1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysOrg:detail1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 411232431943749,
                "name": "机构管理",
                "code": "sys_org_mgr1",
                "type": 1,
                "icon": null,
                "router": "/org",
                "component": "system/org/index",
                "permission": "",
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": "",
                "weight": 1,
                "sort": 100,
                "remark": null
            },
            {
                "id": 417587610206277,
                "children": [
                    {
                        "id": 417587981193285,
                        "children": [],
                        "pid": 417587610206277,
                        "name": "职位查询",
                        "code": "sys_pos_mgr_page1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysPos:page1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417588299284549,
                        "children": [],
                        "pid": 417587610206277,
                        "name": "职位列表",
                        "code": "sys_pos_mgr_list1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysPos:list1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417636766220357,
                        "children": [],
                        "pid": 417587610206277,
                        "name": "职位增加",
                        "code": "sys_pos_mgr_add1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysPos:add1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417638844739653,
                        "children": [],
                        "pid": 417587610206277,
                        "name": "职位编辑",
                        "code": "sys_pos_mgr_edit1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysPos:edit",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417639119773765,
                        "children": [],
                        "pid": 417587610206277,
                        "name": "职位删除",
                        "code": "sys_pos_mgr_delete1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysPos:delete1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 417639270768709,
                        "children": [],
                        "pid": 417587610206277,
                        "name": "职位详情",
                        "code": "sys_pos_mgr_detail1",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "sysPos:detail1",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 411232431943749,
                "name": "职位管理",
                "code": "sys_pos_mgr1",
                "type": 1,
                "icon": null,
                "router": "/pos",
                "component": "system/pos/index",
                "permission": "",
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": "",
                "weight": 1,
                "sort": 101,
                "remark": null
            },
            {
                "id": 411235264139333,
                "children": [],
                "pid": 411232431943749,
                "name": "人员申请列表",
                "code": "supervision_companyMag_applicationList",
                "type": 1,
                "icon": null,
                "router": "/companyMag/applicationList",
                "component": "supervision/companyMag/applicationList",
                "permission": "",
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": "",
                "weight": 2,
                "sort": 105,
                "remark": null
            }
        ],
        "pid": 0,
        "name": "企业管理",
        "code": "sup_companyMag",
        "type": 0,
        "icon": "setting",
        "router": "/companyMag",
        "component": "PageView",
        "permission": "",
        "application": "manage",
        "openType": 0,
        "visible": "Y",
        "link": null,
        "redirect": "",
        "weight": 1,
        "sort": 99,
        "remark": null
    },
    {
        "id": 142307070918746,
        "children": [
            {
                "id": 142307070918747,
                "children": [
                    {
                        "id": 432531756138565,
                        "children": [],
                        "pid": 142307070918747,
                        "name": "可管理",
                        "code": "com_notice_mag",
                        "type": 2,
                        "icon": "",
                        "router": "",
                        "component": "",
                        "permission": "com:notice:mag",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": "",
                        "redirect": "",
                        "weight": 2,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918748,
                        "children": [],
                        "pid": 142307070918747,
                        "name": "公告查询",
                        "code": "sys_notice_mgr_page",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysNotice:page",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918749,
                        "children": [],
                        "pid": 142307070918747,
                        "name": "公告增加",
                        "code": "sys_notice_mgr_add",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysNotice:add",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918750,
                        "children": [],
                        "pid": 142307070918747,
                        "name": "公告编辑",
                        "code": "sys_notice_mgr_edit",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysNotice:edit",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918751,
                        "children": [],
                        "pid": 142307070918747,
                        "name": "公告删除",
                        "code": "sys_notice_mgr_delete",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysNotice:delete",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918752,
                        "children": [],
                        "pid": 142307070918747,
                        "name": "公告查看",
                        "code": "sys_notice_mgr_detail",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysNotice:detail",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918753,
                        "children": [],
                        "pid": 142307070918747,
                        "name": "公告修改状态",
                        "code": "sys_notice_mgr_changeStatus",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysNotice:changeStatus",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 142307070918746,
                "name": "公告管理",
                "code": "sys_notice_mgr",
                "type": 1,
                "icon": null,
                "router": "/notice",
                "component": "system/notice/index",
                "permission": null,
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": null,
                "weight": 1,
                "sort": 100,
                "remark": null
            },
            {
                "id": 142307070918754,
                "children": [
                    {
                        "id": 142307070918755,
                        "children": [],
                        "pid": 142307070918754,
                        "name": "已收公告查询",
                        "code": "sys_notice_mgr_received_page",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysNotice:received",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 142307070918746,
                "name": "已收公告",
                "code": "sys_notice_mgr_received",
                "type": 1,
                "icon": null,
                "router": "/noticeReceived",
                "component": "system/noticeReceived/index",
                "permission": null,
                "application": "manage",
                "openType": 1,
                "visible": "N",
                "link": null,
                "redirect": null,
                "weight": 1,
                "sort": 100,
                "remark": "关闭"
            }
        ],
        "pid": 0,
        "name": "通知公告",
        "code": "sys_notice",
        "type": 0,
        "icon": "sound",
        "router": "/notice",
        "component": "PageView",
        "permission": null,
        "application": "manage",
        "openType": 0,
        "visible": "Y",
        "link": null,
        "redirect": null,
        "weight": 1,
        "sort": 100,
        "remark": null
    },
    {
        "id": 142307000914633,
        "children": [
            {
                "id": 142307070910581,
                "children": [
                    {
                        "id": 142307070910582,
                        "children": [],
                        "pid": 142307070910581,
                        "name": "机构查询",
                        "code": "sys_org_mgr_page",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysOrg:page",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910583,
                        "children": [],
                        "pid": 142307070910581,
                        "name": "机构列表",
                        "code": "sys_org_mgr_list",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysOrg:list",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910584,
                        "children": [],
                        "pid": 142307070910581,
                        "name": "机构增加",
                        "code": "sys_org_mgr_add",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysOrg:add",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910585,
                        "children": [],
                        "pid": 142307070910581,
                        "name": "机构编辑",
                        "code": "sys_org_mgr_edit",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysOrg:edit",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910586,
                        "children": [],
                        "pid": 142307070910581,
                        "name": "机构删除",
                        "code": "sys_org_mgr_delete",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysOrg:delete",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910587,
                        "children": [],
                        "pid": 142307070910581,
                        "name": "机构详情",
                        "code": "sys_org_mgr_detail",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysOrg:detail",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910588,
                        "children": [],
                        "pid": 142307070910581,
                        "name": "机构树",
                        "code": "sys_org_mgr_tree",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysOrg:tree",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 142307000914633,
                "name": "机构管理",
                "code": "sys_org_mgr",
                "type": 1,
                "icon": null,
                "router": "/org",
                "component": "system/org/index",
                "permission": null,
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": null,
                "weight": 1,
                "sort": 100,
                "remark": null
            },
            {
                "id": 142307070910589,
                "children": [
                    {
                        "id": 142307070910590,
                        "children": [],
                        "pid": 142307070910589,
                        "name": "职位查询",
                        "code": "sys_pos_mgr_page",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysPos:page",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910591,
                        "children": [],
                        "pid": 142307070910589,
                        "name": "职位列表",
                        "code": "sys_pos_mgr_list",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysPos:list",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914629,
                        "children": [],
                        "pid": 142307070910589,
                        "name": "职位增加",
                        "code": "sys_pos_mgr_add",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysPos:add",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914630,
                        "children": [],
                        "pid": 142307070910589,
                        "name": "职位编辑",
                        "code": "sys_pos_mgr_edit",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysPos:edit",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914631,
                        "children": [],
                        "pid": 142307070910589,
                        "name": "职位删除",
                        "code": "sys_pos_mgr_delete",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysPos:delete",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914632,
                        "children": [],
                        "pid": 142307070910589,
                        "name": "职位详情",
                        "code": "sys_pos_mgr_detail",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysPos:detail",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 142307000914633,
                "name": "职位管理",
                "code": "sys_pos_mgr",
                "type": 1,
                "icon": null,
                "router": "/pos",
                "component": "system/pos/index",
                "permission": null,
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": null,
                "weight": 1,
                "sort": 100,
                "remark": null
            }
        ],
        "pid": 0,
        "name": "组织架构",
        "code": "sys_mgr",
        "type": 0,
        "icon": "team",
        "router": "/sys",
        "component": "PageView",
        "permission": null,
        "application": "manage",
        "openType": 0,
        "visible": "N",
        "link": null,
        "redirect": null,
        "weight": 1,
        "sort": 111,
        "remark": null
    },
    {
        "id": 142307070910563,
        "children": [
            {
                "id": 142307070910564,
                "children": [
                    {
                        "id": 142307070910565,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户查询",
                        "code": "sys_user_mgr_page",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:page",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910566,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户编辑",
                        "code": "sys_user_mgr_edit",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:edit",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910567,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户增加",
                        "code": "sys_user_mgr_add",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:add",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910568,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户删除",
                        "code": "sys_user_mgr_delete",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:delete",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910569,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户详情",
                        "code": "sys_user_mgr_detail",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:detail",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910570,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户导出",
                        "code": "sys_user_mgr_export",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:export",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910571,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户选择器",
                        "code": "sys_user_mgr_selector",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:selector",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910572,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户授权角色",
                        "code": "sys_user_mgr_grant_role",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:grantRole",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910573,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户拥有角色",
                        "code": "sys_user_mgr_own_role",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:ownRole",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910574,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户授权数据",
                        "code": "sys_user_mgr_grant_data",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:grantData",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910575,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户拥有数据",
                        "code": "sys_user_mgr_own_data",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:ownData",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910576,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户更新信息",
                        "code": "sys_user_mgr_update_info",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:updateInfo",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910577,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户修改密码",
                        "code": "sys_user_mgr_update_pwd",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:updatePwd",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910578,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户修改状态",
                        "code": "sys_user_mgr_change_status",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:changeStatus",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910579,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户修改头像",
                        "code": "sys_user_mgr_update_avatar",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:updateAvatar",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070910580,
                        "children": [],
                        "pid": 142307070910564,
                        "name": "用户重置密码",
                        "code": "sys_user_mgr_reset_pwd",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysUser:resetPwd",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 142307070910563,
                "name": "用户管理",
                "code": "sys_user_mgr",
                "type": 1,
                "icon": null,
                "router": "/mgr_user",
                "component": "system/user/index",
                "permission": null,
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": null,
                "weight": 1,
                "sort": 100,
                "remark": null
            },
            {
                "id": 142307070914651,
                "children": [
                    {
                        "id": 142307070914648,
                        "children": [],
                        "pid": 142307070914651,
                        "name": "菜单授权树",
                        "code": "sys_menu_mgr_grant_tree",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysMenu:treeForGrant",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914649,
                        "children": [],
                        "pid": 142307070914651,
                        "name": "菜单树",
                        "code": "sys_menu_mgr_tree",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysMenu:tree",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914652,
                        "children": [],
                        "pid": 142307070914651,
                        "name": "角色查询",
                        "code": "sys_role_mgr_page",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysRole:page",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914653,
                        "children": [],
                        "pid": 142307070914651,
                        "name": "角色增加",
                        "code": "sys_role_mgr_add",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysRole:add",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914654,
                        "children": [],
                        "pid": 142307070914651,
                        "name": "角色编辑",
                        "code": "sys_role_mgr_edit",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysRole:edit",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914655,
                        "children": [],
                        "pid": 142307070914651,
                        "name": "角色删除",
                        "code": "sys_role_mgr_delete",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysRole:delete",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914656,
                        "children": [],
                        "pid": 142307070914651,
                        "name": "角色详情",
                        "code": "sys_role_mgr_detail",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysRole:detail",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914657,
                        "children": [],
                        "pid": 142307070914651,
                        "name": "角色下拉",
                        "code": "sys_role_mgr_drop_down",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysRole:dropDown",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914658,
                        "children": [],
                        "pid": 142307070914651,
                        "name": "角色授权菜单",
                        "code": "sys_role_mgr_grant_menu",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysRole:grantMenu",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914659,
                        "children": [],
                        "pid": 142307070914651,
                        "name": "角色拥有菜单",
                        "code": "sys_role_mgr_own_menu",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysRole:ownMenu",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914660,
                        "children": [],
                        "pid": 142307070914651,
                        "name": "角色授权数据",
                        "code": "sys_role_mgr_grant_data",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysRole:grantData",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070914661,
                        "children": [],
                        "pid": 142307070914651,
                        "name": "角色拥有数据",
                        "code": "sys_role_mgr_own_data",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysRole:ownData",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 142307070910563,
                "name": "角色管理",
                "code": "sys_role_mgr",
                "type": 1,
                "icon": null,
                "router": "/role",
                "component": "system/role/index",
                "permission": null,
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": null,
                "weight": 1,
                "sort": 100,
                "remark": null
            }
        ],
        "pid": 0,
        "name": "权限管理",
        "code": "auth_manager",
        "type": 0,
        "icon": "safety-certificate",
        "router": "/auth",
        "component": "PageView",
        "permission": null,
        "application": "manage",
        "openType": 0,
        "visible": "N",
        "link": null,
        "redirect": null,
        "weight": 1,
        "sort": 112,
        "remark": null
    },
    {
        "id": 142307070918732,
        "children": [
            {
                "id": 142307070918733,
                "children": [
                    {
                        "id": 142307070918734,
                        "children": [],
                        "pid": 142307070918733,
                        "name": "访问日志查询",
                        "code": "sys_log_mgr_vis_log_page",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysVisLog:page",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918735,
                        "children": [],
                        "pid": 142307070918733,
                        "name": "访问日志清空",
                        "code": "sys_log_mgr_vis_log_delete",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysVisLog:delete",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 142307070918732,
                "name": "访问日志",
                "code": "sys_log_mgr_vis_log",
                "type": 1,
                "icon": null,
                "router": "/vislog",
                "component": "system/log/vislog/index",
                "permission": null,
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": null,
                "weight": 1,
                "sort": 100,
                "remark": null
            },
            {
                "id": 142307070918736,
                "children": [
                    {
                        "id": 142307070918737,
                        "children": [],
                        "pid": 142307070918736,
                        "name": "操作日志查询",
                        "code": "sys_log_mgr_op_log_page",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysOpLog:page",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918738,
                        "children": [],
                        "pid": 142307070918736,
                        "name": "操作日志清空",
                        "code": "sys_log_mgr_op_log_delete",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysOpLog:delete",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 142307070918732,
                "name": "操作日志",
                "code": "sys_log_mgr_op_log",
                "type": 1,
                "icon": null,
                "router": "/oplog",
                "component": "system/log/oplog/index",
                "permission": null,
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": null,
                "weight": 1,
                "sort": 100,
                "remark": null
            },
            {
                "id": 142307070911739,
                "children": [
                    {
                        "id": 142307070911740,
                        "children": [],
                        "pid": 142307070911739,
                        "name": "异常日志查询",
                        "code": "sys_log_mgr_ex_log_page",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysExLog:page",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070911741,
                        "children": [],
                        "pid": 142307070911739,
                        "name": "异常日志清空",
                        "code": "sys_log_mgr_ex_log_delete",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysExLog:delete",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 142307070918732,
                "name": "异常日志",
                "code": "sys_log_mgr_ex_log",
                "type": 1,
                "icon": null,
                "router": "/exlog",
                "component": "system/log/exlog/index",
                "permission": null,
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": null,
                "weight": 1,
                "sort": 100,
                "remark": null
            }
        ],
        "pid": 0,
        "name": "日志管理",
        "code": "sys_log_mgr",
        "type": 0,
        "icon": "read",
        "router": "/log",
        "component": "PageView",
        "permission": null,
        "application": "manage",
        "openType": 0,
        "visible": "Y",
        "link": null,
        "redirect": null,
        "weight": 1,
        "sort": 113,
        "remark": null
    },
    {
        "id": 142307070918756,
        "children": [
            {
                "id": 142307070918757,
                "children": [
                    {
                        "id": 142307070918758,
                        "children": [],
                        "pid": 142307070918757,
                        "name": "文件查询",
                        "code": "sys_file_mgr_sys_file_page",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysFileInfo:page",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918759,
                        "children": [],
                        "pid": 142307070918757,
                        "name": "文件列表",
                        "code": "sys_file_mgr_sys_file_list",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysFileInfo:list",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918760,
                        "children": [],
                        "pid": 142307070918757,
                        "name": "文件删除",
                        "code": "sys_file_mgr_sys_file_delete",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysFileInfo:delete",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918761,
                        "children": [],
                        "pid": 142307070918757,
                        "name": "文件详情",
                        "code": "sys_file_mgr_sys_file_detail",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysFileInfo:detail",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918762,
                        "children": [],
                        "pid": 142307070918757,
                        "name": "文件上传",
                        "code": "sys_file_mgr_sys_file_upload",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysFileInfo:upload",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918763,
                        "children": [],
                        "pid": 142307070918757,
                        "name": "文件下载",
                        "code": "sys_file_mgr_sys_file_download",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysFileInfo:download",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070918764,
                        "children": [],
                        "pid": 142307070918757,
                        "name": "图片预览",
                        "code": "sys_file_mgr_sys_file_preview",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysFileInfo:preview",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070922874,
                        "children": [],
                        "pid": 142307070918757,
                        "name": "头像上传",
                        "code": "sys_file_mgr_sys_file_upload_avatar",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysFileInfo:uploadAvatar",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070922875,
                        "children": [],
                        "pid": 142307070918757,
                        "name": "文档上传",
                        "code": "sys_file_mgr_sys_file_upload_document",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysFileInfo:uploadDocument",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    },
                    {
                        "id": 142307070922876,
                        "children": [],
                        "pid": 142307070918757,
                        "name": "商城上传",
                        "code": "sys_file_mgr_sys_file_upload_shop",
                        "type": 2,
                        "icon": null,
                        "router": null,
                        "component": null,
                        "permission": "sysFileInfo:uploadShop",
                        "application": "manage",
                        "openType": 0,
                        "visible": "Y",
                        "link": null,
                        "redirect": null,
                        "weight": 1,
                        "sort": 100,
                        "remark": null
                    }
                ],
                "pid": 142307070918756,
                "name": "系统文件",
                "code": "sys_file_mgr_sys_file",
                "type": 1,
                "icon": null,
                "router": "/file",
                "component": "system/file/index",
                "permission": null,
                "application": "manage",
                "openType": 1,
                "visible": "Y",
                "link": null,
                "redirect": null,
                "weight": 1,
                "sort": 100,
                "remark": null
            }
        ],
        "pid": 0,
        "name": "文件管理",
        "code": "sys_file_mgr",
        "type": 0,
        "icon": "file",
        "router": "/file",
        "component": "PageView",
        "permission": null,
        "application": "manage",
        "openType": 0,
        "visible": "Y",
        "link": null,
        "redirect": null,
        "weight": 1,
        "sort": 114,
        "remark": null
    }
]
export default treeMenuData